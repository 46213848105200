import type { MetaFunction } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Calendar, dateFnsLocalizer, type Event } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'en-GB': enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const meta: MetaFunction = () => {
  return [{ title: 'Home' }];
};

export async function loader() {
  const events: Event[] = [];

  return { events };
}

export default function Dashboard() {
  const { events } = useLoaderData<typeof loader>();

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Home</h1>
      <div className="h-[80vh] bg-white p-4 rounded-lg shadow">
        <Calendar
          culture={'en-GB'}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
        />
      </div>
      <Outlet />
    </div>
  );
}
